import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import moment from "moment";
import "moment-timezone";
import { Link, useParams } from "react-router-dom";

import './style.css';

const CityCard = (props) => {

    const params = useParams();
    const [date, setDate] = useState('');
    const [hour, setHour] = useState('');
    const [intervalId, setIntervalId] = useState();
    const [currency, setCurrency] = useState(0);
    const [temp, setTemp] = useState(0);

    const [dolar, setDolar] = useState(0);
    const [euro, setEuro] = useState(0);

    const textTip = {
        en: 'Tips for you',
        es: '¡Consejo a la vista!',
        pt: 'Dicas à vista'
    }

    const textPlaylist = {
        en: 'Scan the Hitlist Infinite',
        es: 'Escanee la Hitlist Infinite',
        pt: 'Scanneie a Hitlist Infinite',
    }

    const textWeather = {
        en: 'With an eye on the weather',
        es: 'Atento al clima',
        pt: 'De olho no clima'
    }

    const textExchange = {
        en: 'Local exchange rates',
        es: 'Cambio local',
        pt: 'Câmbio local'
    }

    const cultureText = {
        en: 'Culture',
        es: 'Cultura',
        pt: 'Cultura',
    }
    const experienceText = {
        en: 'Experience',
        es: 'Experiencia',
        pt: 'Experiência',
    }

    const gastronomyText = {
        en: 'Gastronomy',
        es: 'Gastronomía',
        pt: 'Gastronomia',
    }

    const spotyfyText = {
        "en": "Listen to the exclusive Visa playlist on Spotify",
        "es": "Escuche una playlist Visa exclusiva en Spotify",
        "pt": "Escute uma playlist Visa exclusiva no Spotify"
    }

    const qrCodeText = {
        "en": "Scan the QR Code using your cell phone camera",
        "es": "Escanee el QR Code con la cámara del celular",
        "pt": "Escaneie o QR Code com a câmera do celular"
    }

    useEffect(() => {
        if (props.city && Object.keys(props.city).length === 0 && Object.getPrototypeOf(props.city) === Object.prototype) {
        } else {

            // fetch(`https://economia.awesomeapi.com.br/json/last/${props.city.currency}`)
            //     .then(response => response.json())
            //     .then(data => {
            //         let currencyCode = Object.values(data)[0].code;
            //         let currencyCodein = Object.values(data)[0].codein;
            //         let currencyBid = parseFloat(Object.values(data)[0].bid).toFixed(2);
            //         setCurrency(`${currencyCode} 1.00 - ${currencyCodein} ${currencyBid}`);
            //     });

            //DOLAR
            fetch(`https://economia.awesomeapi.com.br/json/last/USD-BRL`)
                .then(response => response.json())
                .then(data => {
                    let currencyCode = Object.values(data)[0].code;
                    let currencyCodein = Object.values(data)[0].codein;
                    let currencyBid = parseFloat(Object.values(data)[0].bid).toFixed(2);
                    setDolar(currencyBid);
                });

            //EURO
            fetch(`https://economia.awesomeapi.com.br/json/last/EUR-BRL`)
                .then(response => response.json())
                .then(data => {
                    let currencyCode = Object.values(data)[0].code;
                    let currencyCodein = Object.values(data)[0].codein;
                    let currencyBid = parseFloat(Object.values(data)[0].bid).toFixed(2);
                    setEuro(currencyBid);
                });

            fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${props.city.latitude}&lon=${props.city.longitude}&appid=b602b4a61d68b3bf25144484bc303330&lang=pt_br&units=metric`)
                .then(response => response.json())
                .then(data => {
                    setTemp(data.main.temp);
                });
        }

        const interval = setInterval(() => {
            changeTime();
        }, 1000);
        setIntervalId(interval);
        return () => clearInterval(interval);
    }, [props.city])

    const changeTime = () => {
        setDate(moment.tz(props.city.timezone).format('DD/MM/YY'));
        setHour(moment.tz(props.city.timezone).format('HH:mm:ssA'));
    }

    return (
        <div className={props.loading ? "city-card" : "city-card active"}>
            <h1>{props.city.name}</h1>
            <h2>{props.city.country}</h2>

            <p className="date">
                <span>{date}</span>
                <img src={`${process.env.PUBLIC_URL}/images/clock.png`} />
                <span>{hour}</span>
            </p>

            <h3>{textTip[params.lang]}</h3>
            <nav>
                <ul>
                    <li>
                        <Link to={`/${params.lang}/globe/${params.city}/culture`}>
                            <img src={`${process.env.PUBLIC_URL}/images/icon-culture.png`} />
                            <span>{cultureText[params.lang]}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/${params.lang}/globe/${params.city}/experience`}>
                            <img src={`${process.env.PUBLIC_URL}/images/icon-experience.png`} />
                            <span>{experienceText[params.lang]}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/${params.lang}/globe/${params.city}/gastronomy`}>
                            <img src={`${process.env.PUBLIC_URL}/images/icon-gastronomy.png`} />
                            <span>{gastronomyText[params.lang]}</span>
                        </Link>
                    </li>
                </ul>
            </nav>

            <div className="spotify">
                <a href={props.city.playlist} target="_blank">
                    <span className="text-1">{spotyfyText[params.lang]}</span>
                    <img src={`${process.env.PUBLIC_URL}/images/spotify.png`} className="img-1" />
                </a>
                <img src={`${process.env.PUBLIC_URL}/images/playlists/${params.city}.png`} className="img-2" />
                <span className="text-2">{qrCodeText[params.lang]}</span>
            </div>

            <div className="footer">
                <div className="footer-item">
                    <h4>{textWeather[params.lang]}</h4>
                    <p><img src={`${process.env.PUBLIC_URL}/images/weather.png`} className="weather" /> {temp}ºC</p>
                </div>
                <div className="footer-item">
                    <h4>{textExchange[params.lang]}</h4>
                    <p><img src={`${process.env.PUBLIC_URL}/images/exchange.png`} className="exchange" /> <span>$</span> R${dolar} &nbsp;  &nbsp; <span>€</span> R${euro}</p>
                </div>
            </div>
        </div>
    )
}

export default CityCard;