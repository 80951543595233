import './style.css';
import { useParams } from 'react-router-dom';

const BenefitsHome = (props) => {

    const params = useParams();

    return (
        <div className="benefits-home">
            <div className="left">
                <img src={`${process.env.PUBLIC_URL}/images/benefits/${params.lang}/home-0.png`} />
            </div>
            <div className="right">
                <button onClick={() => { props.onHandlePage('travel') }}>
                    <img src={`${process.env.PUBLIC_URL}/images/benefits/${params.lang}/home-1.png`} />
                </button>
                <button onClick={() => { props.onHandlePage('purchases') }}>
                    <img src={`${process.env.PUBLIC_URL}/images/benefits/${params.lang}/home-2.png`} />
                </button>
                <button onClick={() => { props.onHandlePage('signature') }}>
                    <img src={`${process.env.PUBLIC_URL}/images/benefits/${params.lang}/home-3.png`} />
                </button>
                <button onClick={() => { props.onHandlePage('concierge') }}>
                    <img src={`${process.env.PUBLIC_URL}/images/benefits/${params.lang}/home-4.png`} />
                </button>
            </div>
        </div>
    )
}

export default BenefitsHome;