import { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";

import Terms from '../Terms';
import BenefitsHome from '../BenefitsHome';
import BenefitsCategory from '../BenefitsCategory';
import BenefitsCategoryOne from '../BenefitsCategoryOne';

import './style.css';

const Benefits = () => {

    const [page, setPage] = useState('home');
    const [loading, setLoading] = useState(true);
    const params = useParams();

    const homeText = {
        en: 'Home',
        es: 'Home',
        pt: 'Home',
    }

    const signatureText = {
        en: 'Subscriptions',
        es: 'Suscripciones',
        pt: 'Assinaturas',
    }

    const travelText = {
        en: 'Trips',
        es: 'Viajes',
        pt: 'Viagens',
    }

    const purchasesText = {
        en: 'Purchases and Service',
        es: 'Compras y Servicios',
        pt: 'Compras e Serviços',
    }

    const conciergeText = {
        en: 'Concierge',
        es: 'Concierge',
        pt: 'Concierge',
    }

    const termsText = {
        en: '**Terms and Conditions apply',
        es: '**Términos y Condiciones se aplican',
        pt: '**Termos e condições se aplicam'
    }

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);            
        }, 500);
    }, []);

    const handlePage = (page) => {
        setPage(page);
    }

    return (
        <div className={loading ? "benefits" : "benefits active"}>
            <div className="benefits-title">
                <Link to="/" className="btn-globe">
                    <img src={`${process.env.PUBLIC_URL}/images/icon-globe.png`} alt="Voltar" />
                </Link>
                <h1>Benefícios <span>Visa Infinite</span></h1>
            </div>

            <div className="benefits-menu">
                <img className="benefits-qrcode" src={`${process.env.PUBLIC_URL}/images/benefits/${params.lang}/qr-code.png`} alt="Voltar" />
                <ul>
                    <li className={(page === 'home') ? 'active' : null}>
                        <button onClick={() => handlePage('home')}>
                            <img src={`${process.env.PUBLIC_URL}/images/benefits/home.png`} />
                            <span>{homeText[params.lang]}</span>
                        </button>
                    </li>
                    <li className={(page === 'travel') ? 'active' : null}>
                        <button onClick={() => handlePage('travel')}>
                            <img src={`${process.env.PUBLIC_URL}/images/benefits/travel.png`} />
                            <span>{travelText[params.lang]}</span>
                        </button>
                    </li>
                    <li className={(page === 'purchases') ? 'active' : null}>
                        <button onClick={() => handlePage('purchases')}>
                            <img src={`${process.env.PUBLIC_URL}/images/benefits/purchases.png`} />
                            <span>{purchasesText[params.lang]}</span>
                        </button>
                    </li>
                    <li className={(page === 'signature') ? 'active' : null}>
                        <button onClick={() => handlePage('signature')}>
                            <img src={`${process.env.PUBLIC_URL}/images/benefits/signature.png`} />
                            <span>{signatureText[params.lang]}</span>
                        </button>
                    </li>
                    <li className={(page === 'concierge') ? 'active' : null}>
                        <button onClick={() => handlePage('concierge')}>
                            <img src={`${process.env.PUBLIC_URL}/images/benefits/concierge.png`} />
                            <span>{conciergeText[params.lang]}</span>
                        </button>
                    </li>
                </ul>
            </div>

            {(page === 'home') ? <BenefitsHome onHandlePage={handlePage} /> : null}
            {(page === 'travel' || page === 'purchases' || page === 'signature') ? <BenefitsCategory category={page} /> : null}
            {(page === 'concierge') ? <BenefitsCategoryOne category={page} /> : null}            
            <img src={`${process.env.PUBLIC_URL}/images/benefits-background.jpg`} className="benefits-background" />
            <div className="terms"><p>{termsText[params.lang]}</p></div >
        </div>
    )
}

export default Benefits;