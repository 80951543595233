import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import Globe from 'react-globe.gl';

import InitialScreen from '../InitialScreen';
import Terms from '../Terms';
import Loading from '../Loading';

import './style.css';

const GlobeWrapper = (props) => {

    const initial = useSelector(state => state.initial);
    const cities = useSelector(state => state.cities);
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const globeEl = useRef();
    const dispatch = useDispatch();
    const [zoom, setZoom] = useState(0);
    const [globeW, setGlobeW] = useState(0);
    const [globeH, setGlobeH] = useState(0);
    const [nameVar, setNameVar] = useState('name_pt');

    useEffect(() => {
        updateData();
    }, []);

    useEffect(() => {
        updateData();
    }, [params]);

    const updateData = () => {
        const { innerWidth, innerHeight } = window;

        if (innerWidth >= 1080) {
            setGlobeW(1080);
            setGlobeH(1460);
        } else {
            setGlobeW(innerWidth);
            setGlobeH(innerHeight - 168);
        }

        if(params.lang === 'pt'){
            setNameVar('name_pt');
        }else if(params.lang === 'es'){
            setNameVar('name_es');
        }else if(params.lang === 'en'){
            setNameVar('name');
        }

        //?all=on
        fetch('https://visa.blumerland.com.br/api/cities')
            .then(response => response.json())
            .then(data => {
                if (data.cities && data.cities.constructor === Array && data.cities.length > 0) {
                    dispatch({ type: 'CHANGE_CITIES', cities: data.cities });
                }
            });
    }

    const handleClick = (city) => {
        if (params.lang === undefined) {
            navigate(`/pt/globe/${city}`);
        } else {
            navigate(`/${params.lang}/globe/${city}`);
        }
    }

    const handleZoom = (type) => {
        const camera = globeEl.current.camera();
        if (type === '+') {
            if (zoom < 4) {
                camera.zoom += .2;
                camera.updateProjectionMatrix();
                setZoom(zoom + 1);
            }
        } else if (type === '-') {
            if (zoom > 0) {
                camera.zoom -= .2;
                camera.updateProjectionMatrix();
                setZoom(zoom - 1);
            }
        }
    }

    useEffect(() => {
        // const globe = globeEl.current;
        // globe.controls().maxDistance = 300;
        // globe.controls().minDistance = 180;
        // globe.controls().autoRotate = true;
        // globe.controls().autoRotateSpeed = 0.15;
        // globe.controls().enableZoom = false;

        if (cities.length > 0) {
            setLoading(false);
        }
    }, [cities]);

    return (
        <>
            <div className={(loading) ? "globe" : "globe active"}>
                <Globe
                    ref={globeEl}
                    globeImageUrl={`${process.env.PUBLIC_URL}/images/globe.jpg`}
                    backgroundImageUrl={`${process.env.PUBLIC_URL}/images/night-sky.png`}
                    width={globeW}
                    height={globeH}
                    htmlElementsData={cities}
                    htmlElement={d => {
                        const el = document.createElement('div');
                        el.innerHTML = `<div class="marker"><img src="${process.env.PUBLIC_URL}/images/marker.png" /><span>${d[nameVar]}</span>`;

                        el.style['pointer-events'] = 'auto';
                        el.style.cursor = 'pointer';
                        el.onclick = () => {
                            console.info(d);
                            handleClick(d.url);
                        }
                        return el;
                    }}
                />
                {/* <div>
                    {cities.map((city) => (
                        <button className="globeButton" onClick={() => handleClick(city.url)} key={city.id} >
                            {city[nameVar]}
                        </button>)
                    )}
                </div> */}
                <div className="globe-zoom">
                    <button onClick={() => handleZoom('+')} >+</button>
                    <button onClick={() => handleZoom('-')} >-</button>
                </div>
                <InitialScreen initial={initial} />
            </div>
            {loading ? <Loading /> : null}
        </>
    )
}

export default GlobeWrapper;