import './style.css';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const BenefitsCategory = (props) => {

    const [itens, setItens] = useState(0);
    const margin = 20;
    const width = 430;
    const [wrapperWidth, setWrapperWidth] = useState(0);
    const [wrapperPosition, setWrapperPosition] = useState(0);
    const [position, setPosition] = useState(0);
    const params = useParams();

    useEffect(() => {
        setPosition(0);
        if (props.category === 'travel') {
            setItens(10);
        } else if (props.category === 'purchases') {
            setItens(3);
        } else if (props.category === 'signature') {
            setItens(3);
        } else if (props.category === 'concierge') {
            setItens(0);
        }

    }, [props.category]);

    useEffect(() => {
        const { innerWidth, innerHeight } = window;

        if (innerWidth >= 1080) {
            setWrapperWidth(itens * (width + margin));
        } else {
            setWrapperWidth(innerWidth)
        }
        
    }, [itens]);

    useEffect(() => {
        const { innerWidth, innerHeight } = window;

        if (innerWidth >= 1080) {
            setWrapperPosition((position * (width + margin) * -1) + 45);
        } else {
            setWrapperPosition(0);
        }
        
    }, [position]);

    const handleClick = (type) => {
        if (type == '-') {
            const newPosition = position - 1;
            if (newPosition < 0) {
                newPosition = 0;
            }
            setPosition(newPosition);
        } else if (type == '+') {
            const newPosition = position + 1;
            if (newPosition > itens) {
                newPosition = itens;
            }
            setPosition(newPosition);
        }
    }

    return (
        <div className="benefits-category">
            <div className="left">
                <img src={`${process.env.PUBLIC_URL}/images/benefits/${params.lang}/${props.category}/0.png`} />
            </div>
            <div className="right">
                <div className="benefits-gallery-carousel">
                    <div className="benefits-gallery-wrapper" style={{ width: wrapperWidth, transform: `translate(${wrapperPosition}px, 0)` }}>
                        {[...Array(itens)].map((x, i) => (
                            <div className="item" key={i}>
                                <img src={`${process.env.PUBLIC_URL}/images/benefits/${params.lang}/${props.category}/${i + 1}.png`} />
                            </div>)
                        )}
                    </div>
                </div>
                {
                    (position > 0) ? (
                        <button onClick={() => handleClick('-')} className="button-nav button-prev">
                            <img src={`${process.env.PUBLIC_URL}/images/icon-prev.png`} />
                        </button>
                    ) : null
                }
                {
                    (position < itens - 1) ? (
                        <button onClick={() => handleClick('+')} className="button-nav button-next">
                            <img src={`${process.env.PUBLIC_URL}/images/icon-next.png`} />
                        </button>
                    ) : null
                }

            </div>
        </div>
    )
}

export default BenefitsCategory;