import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './style.css';
import CategorySectionCard from '../CategorySectionCard';

const CategorySectionCarousel = (props) => {
    const [screenWidth, setScreenWidth] = useState(0);
    const [cardWidth, setCardWidth] = useState(0);
    const [wrapperWidth, setWrapperWidth] = useState(0);
    const [wrapperPosition, setWrapperPosition] = useState(0);
    const [wrapperDistance, setWrapperDistance] = useState(0);

    const [data, setData] = useState([]);
    const params = useParams();
    const [active, setActive] = useState(true);

    useEffect(() => {
        const { innerWidth, innerHeight } = window;

        if (innerWidth >= 1080) {
            setScreenWidth(1080);
            setCardWidth(770);
            setWrapperWidth(2150);
        } else {
            setScreenWidth(innerWidth);
            setCardWidth(innerWidth - 30);
            setWrapperWidth(innerWidth - 30);
        }
    }, []);

    useEffect(() => {
        const { innerWidth, innerHeight } = window;

        if (innerWidth >= 1080) {
            setWrapperDistance(((690 * wrapperPosition) * -1) + 155);
            // setWrapperDistance((cardWidth * wrapperPosition * -1) + (screenWidth / 2) - (cardWidth / 2));
        } else {
            setWrapperDistance(0)
        }
        
    }, [wrapperPosition]);

    useEffect(() => {
        setActive(false);

        setTimeout(() => {
            setWrapperPosition(0);
            const newData = props.tips.filter(element => element.category === params.category);
            setData(newData);
        }, 250);

        setTimeout(() => {
            setActive(true);
        }, 600);
    }, [params]);

    const handlePosition = (type) => {
        if (type === '-') {
            setWrapperPosition(wrapperPosition - 1);
        } else if (type) {
            setWrapperPosition(wrapperPosition + 1);
        }
    }

    return (
        <div className={active ? "category-section-carousel active" : "category-section-carousel"}>
            <div className="category-section-carousel-wrapper" style={{ width: `${wrapperWidth}px`, transform: `translate(${wrapperDistance}px, 0)` }}>
                {
                    data.map(card => (
                        <CategorySectionCard
                            key={card.id}
                            name={card.name}
                            description={card.description}
                            image={card.image}
                            active={wrapperPosition === card.order}
                        />)
                    )
                }
            </div>
            {
                (wrapperPosition > 0) ? (
                    <button onClick={() => handlePosition('-')} className="icon-prev" >
                        <img src={`${process.env.PUBLIC_URL}/images/icon-prev.png`} />
                    </button>
                ) : null
            }
            {
                (wrapperPosition < 2) ? (
                    <button onClick={() => handlePosition('+')} className="icon-next" >
                        <img src={`${process.env.PUBLIC_URL}/images/icon-next.png`} />
                    </button>

                ) : null
            }
        </div>
    )
}

export default CategorySectionCarousel;