import { useState, useEffect } from 'react';

import './style.css';

const CategoryGallery = (props) => {

    let tmpLast = 0;
    let tmpActual = 1;
    const [lastImage, setLastImage] = useState(0);
    const [actualImage, setActualImage] = useState(1);

    useEffect(() => {
        if(props.images.length > 1){
            changeImage();
        }
    }, [actualImage]);

    const changeImage = () => {
        setTimeout(() => {
            tmpLast = actualImage;
            tmpActual = actualImage + 1;

            if(tmpActual > (props.images.length - 1)){
                tmpActual = 0;
            }

            setLastImage(tmpLast);
            setActualImage(tmpActual);
        }, 5000);
    }   

    return (
        <div className="city-category-gallery">
            { props.images.map( (image, index) => <img key={image.id} src={image.name} className={ (index === actualImage) ? 'active toUp' : (index === lastImage) ? 'active' : '' } />) }
        </div>
    )
}

export default CategoryGallery;