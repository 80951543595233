import './style.css';
import { useParams } from 'react-router-dom';

const CategorySectionCard = (props) => {

    const params = useParams();

    const conciergeText = {
        en: 'Contact the concierge.',
        es: 'Hable con el conserje.',
        pt: 'Fale com o concierge.'
    }

    return (
        <div className={props.active ? "category-section-card active" : "category-section-card"}>
            <div className="image">
                <img src={props.image} className="thumb" />
            </div>
            <div className="content-card">
                <div className="content-left">
                    <img src={`${process.env.PUBLIC_URL}/images/qrcode.png`} />
                    <span>{conciergeText[params.lang]}</span>
                    <a href="https://www.visa.com/digitalconcierge" target="_blank">{conciergeText[params.lang]}</a>
                </div>
                <div className="content-right">
                    <h5>{props.name}</h5>
                    <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
                </div>
            </div>
        </div>
    )
}

export default CategorySectionCard;