import { useParams, Link } from 'react-router-dom';

import './style.css';
import CategorySectionCarousel from '../CategorySectionCarousel';

const CategorySection = (props) => {

    const params = useParams();

    const textTip = {
        en: 'Tips in sight',
        es: 'Consejos a la vista',
        pt: 'Dicas à vista',
    }    

    const cultureText = {
        en: 'Culture',
        es: 'Cultura',
        pt: 'Cultura',
    }
    const experienceText = {
        en: 'Experience',
        es: 'Experiencia',
        pt: 'Experiência',
    }

    const gastronomyText = {
        en: 'Gastronomy',
        es: 'Gastronomía',
        pt: 'Gastronomia',
    }

    return (
        <div className="category-section active">
            <CategorySectionCarousel tips={props.tips} />
            <nav>
                <span>{textTip[params.lang]}</span>
                <ul>
                    <li className={ (params.category === 'culture') ? 'active' : '' }>
                        <Link to={`/${params.lang}/globe/${params.city}/culture`}>
                            <img src={`${process.env.PUBLIC_URL}/images/icon-culture.png`} />
                            <span>{cultureText[params.lang]}</span>
                        </Link>
                    </li>
                    <li className={ (params.category === 'experience') ? 'active' : '' }>
                        <Link to={`/${params.lang}/globe/${params.city}/experience`}>
                            <img src={`${process.env.PUBLIC_URL}/images/icon-experience.png`} />
                            <span>{experienceText[params.lang]}</span>
                        </Link>
                    </li>
                    <li className={ (params.category === 'gastronomy') ? 'active' : '' }>
                        <Link to={`/${params.lang}/globe/${params.city}/gastronomy`}>
                            <img src={`${process.env.PUBLIC_URL}/images/icon-gastronomy.png`} />
                            <span>{gastronomyText[params.lang]}</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default CategorySection;