import { useIdleTimer } from 'react-idle-timer';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import Header from './components/Header';
import Content from './components/Content';

function App() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const timeout = 60 * 1000 * 3;
    const [isIdle, setIsIdle] = useState(false);

    const handleOnActive = () => {
        setIsIdle(false);
    }

    const handleOnIdle = () => {
        setIsIdle(true);
        dispatch({ type: 'CHANGE_INITIAL', initial: true });
        dispatch({ type: 'CHANGE_MENU', menu: false });
        navigate('/');
    }

    const idleTimer = useIdleTimer({
        timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle
    });

    const handleClick = () => {
        dispatch({ type: 'CHANGE_INITIAL', initial: false });
    }

    return (
        <div className="App" onClick={() => handleClick()}>
            <Header />
            <Content />
        </div>
    );
}

export default App;
