import { useState } from 'react';
import { useParams } from 'react-router-dom';
import './style.css';

const Terms = () => {
    const [active, setActive] = useState(false);
    const params = useParams();

    const handleClick = () => {
        setActive(!active);
    }

    const button1Text = {
        en: 'See more...',
        es: 'Ver más...',
        pt: 'Ver mais...'
    }

    const button2Text = {
        en: 'See less...',
        es: 'Ver menos...',
        pt: 'Ver menos...'
    }

    const term1Text = {
        en: 'Content developed by Ten Serviços de Concierge do Brasil Ltda.',
        es: 'Contenido desarrollado por Ten Serviços de Concierge do Brasil Ltda.',
        pt: 'Conteúdo desenvolvido pela Ten Serviços de Concierge do Brasil Ltda.'
    }

    const term2Text = {
        en: 'The Visa Digital Concierge Program is managed by Ten Serviços de Concierge do Brasil Ltda. and will respond to requests as available. Requests that are absurd, inappropriate or which violate morals, ethics or the law will be refused. Visa Digital Concierge is available free of charge to eligible Visa cardholders in Latin America and the Caribbean. It is the responsibility of the Visa Platinum or Visa Infinite cardholder to bear the costs of services and products sold by third parties via Visa Digital Concierge, such as hotel rates, airline fares, tickets to concerts and events, and delivery of documents, goods and services. The services offered are subject to the availability of the providers offering them.',
        es: 'El Programa Visa Digital Concierge es administrado por Ten Serviços de Concierge do Brasil Ltda. y atenderá las solicitudes conforme su disponibilidad. Pedidos absurdos, inadecuados o que atenten contra la moral, la ética o la ley serán negados. Visa Digital Concierge está disponible sin cargo para los titulares de tarjetas Visa elegibles en América Latina y el Caribe. Es de responsabilidad del portador de la tarjeta Visa Platinum o Visa Infinite arcar con los costos de los servicios y productos comercializados por terceros por Visa Digital Concierge, tales como diarias de hotel, pasajes aéreos, boletos para espectáculos y eventos y entrega de documentos, bienes y servicios. Los servicios ofrecidos están sujetos a la disponibilidad de los proveedores que los ofrecen.',
        pt: 'O Programa Visa Digital Concierge é administrado pela Ten Serviços de Concierge do Brasil Ltda e atenderá às solicitações conforme disponibilidade. Pedidos absurdos, inadequados ou que atentarem contra a moral, a ética ou a lei serão recusados. O Visa Digital Concierge está disponível gratuitamente para portadores de cartões Visa elegíveis na América Latina e no Caribe. É de responsabilidade do portador do cartão Visa Platinum ou Visa Infinite arcar com os custos dos serviços e produtos comercializados por terceiros pelo Visa Digital Concierge, tais como diárias de hotel, passagens aéreas, ingressos para shows e eventos e entrega de documentos, bens e serviços. Os serviços oferecidos estão sujeitos à disponibilidade dos fornecedores que os oferecem.'
    }


    return (
        <div className={active ? "terms active" : "terms"}>
            {active ? (
                <>
                <p>{term1Text[params.lang]} <button onClick={() => handleClick()}>{button2Text[params.lang]}</button></p>
                <p>{term2Text[params.lang]}</p>
                </>
            ) : (
                <p>{term1Text[params.lang]} <button onClick={() => handleClick()}>{button1Text[params.lang]}</button></p>
            )
            }
        </div >

    )
}

export default Terms;