import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router'
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';

import './style.css';

const Header = () => {

    const initial = useSelector(state => state.initial);
    const menuActive = useSelector(state => state.menu);
    const [lang, setLang] = useState('pt');
    const location = useLocation();
    const dispatch = useDispatch();
    const [currentUrl, setCurrentUrl] = useState(''); 

    const headerText = {
        en: 'Discover amazing experiences!',
        es: '¡Descubra experiencias increíbles!',
        pt: 'Descubra experiências incríveis!',
    }

    const languageText = {
        en: 'Languages',
        es: 'Idiomas',
        pt: 'Idiomas',
    }

    const benefitsText = {
        en: 'Benefits',
        es: 'Beneficios',
        pt: 'Benefícios'
    }

    const projectText = {
        en: 'VISA Around the World',
        es: 'VISA Por el Mundo',
        pt: 'VISA Pelo Mundo'
    }

    useEffect(() => {
        handleLanguage();
    }, [location]);

    useEffect(() => {
        handleLanguage();
    }, [])

    const handleLanguage = () => {
        if (window.location.pathname == '/') {
            setLang('pt');
            setCurrentUrl('');
        } else {
            const { params } = matchPath({ path: "/:lang/*" }, window.location.pathname);
            setLang(params.lang);
            setCurrentUrl(params['*']);
        }
    }

    const handleClick = () => {
        dispatch({ type: 'CHANGE_MENU', menu: !menuActive });
    }

    const handleMenu = () => {
        dispatch({ type: 'CHANGE_MENU', menu: false });
    }

    return (
        <header>
            <div className="header-top" onClick={() => handleMenu()}>
                <img src={`${process.env.PUBLIC_URL}/images/visa-logo-${lang}.jpg`} />
            </div>
            {
                (initial) ?
                    (
                        <div className="header-bottom-initial">
                            <img src={`${process.env.PUBLIC_URL}/images/header-head-icon.png`} />
                            <span>{headerText[lang]}</span>
                        </div>
                    )
                    :
                    (
                        <div className="header-bottom">
                            <div onClick={() => handleClick()} className={menuActive ? "menu-button-wrapper active" : "menu-button-wrapper"}>
                                <div className="menu-button"></div>
                            </div>
                            <img src={`${process.env.PUBLIC_URL}/images/header-qrcode-${lang}.png`} />
                            <nav className={menuActive ? "menu-hover active" : "menu-hover"}>
                                <ul>
                                    <li>{languageText[lang]}</li>
                                    <li className="list-lang">
                                        <Link onClick={() => handleClick()} to={`/en/${currentUrl}`} className={lang === 'en' ? "active" : null}>ENG</Link>
                                        <Link onClick={() => handleClick()} to={`/es/${currentUrl}`} className={lang === 'es' ? "active" : null}>ESP</Link>
                                        <Link onClick={() => handleClick()} to={`/pt/${currentUrl}`} className={lang === 'pt' ? "active" : null}>POR</Link>
                                    </li>
                                    <li><Link onClick={() => handleClick()} to={`/${lang}/benefits`}>{benefitsText[lang]}</Link></li>
                                    <li><Link onClick={() => handleClick()} to={`/${lang}/globe`}>{projectText[lang]}</Link></li>
                                </ul>
                            </nav>
                            <nav className="fixed">
                                <ul>
                                    <li className="list-lang">
                                        <Link to={`/en/${currentUrl}`} className={lang === 'en' ? "active" : null}>ENG</Link>
                                        <Link to={`/es/${currentUrl}`} className={lang === 'es' ? "active" : null}>ESP</Link>
                                        <Link to={`/pt/${currentUrl}`} className={lang === 'pt' ? "active" : null}>POR</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    )
            }
        </header>
    )
}

export default Header;