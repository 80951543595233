import './style.css';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import SwitchLanguage from '../SwitchLanguage'

const InitialScreen = (props) => {

    const params = useParams();
    const [lang, setLang] = useState('pt');

    useEffect(() => {
        if(params.lang == undefined){
            setLang('pt');
        }else{
            setLang(params.lang);
        }
    }, [params])

    return (
        <div className={props.initial ? "initial active" : "initial"}>
            <div className="initial-center">
                <img src={`${process.env.PUBLIC_URL}/images/initial-${lang}.png`} className="initial-qrcode" />
                <img src={`${process.env.PUBLIC_URL}/images/initial-mobile-${lang}.png`} className="initial-mobile-qrcode" />
            </div>
            <SwitchLanguage />
        </div>
    )
}

export default InitialScreen;