import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import './style.css';
import CategorySection from '../CategorySection';
import CategoryGallery from '../CategoryGallery';
import Terms from '../Terms';
import Loading from '../Loading';

const Category = () => {

    const [loading, setLoading] = useState(true);
    const [categoryName, setCategoryName] = useState('');
    const params = useParams();
    const content = useSelector(state => state.content);
    const dataStored = useSelector(state => state.data);
    const [city, setCity] = useState({});
    const url = 'https://visa.blumerland.com.br/api/city/';
    const dispatch = useDispatch();

    useEffect(() => {
        if (dataStored[params.lang].length > 0) {
            const newData = dataStored[params.lang].filter(element => element.url === params.city);
            if (newData.length > 0) {
                setCity(newData[0]);
                setLoading(false);
            } else {
                getData();
            }
        } else {
            getData();
        }
    }, [dataStored]);

    useEffect(() => {
        const newCategoryName = content[params.category].filter(element => element.language === params.lang);
        setCategoryName(newCategoryName[0].name);
    }, [params]);

    const getData = () => {
        fetch(`${url}${params.city}/${params.lang}`)
            .then(response => response.json())
            .then(data => {
                const newDataStored = dataStored;
                newDataStored[params.lang].push(data.city);
                setCity(data.city);
                setLoading(false);
                dispatch({ type: 'CHANGE_DATA', data: newDataStored });
            });
    }

    return (
        <>
            <div className={loading ? "category" : "category active"} style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/category-background.jpg")` }}>
                <div className="category-menu">
                    <Link to={`/${params.lang}/globe`} className="btn-globe">
                        <img src={`${process.env.PUBLIC_URL}/images/icon-globe.png`} alt="Voltar" />
                    </Link>
                    <Link to={`/${params.lang}/globe/${params.city}`} className="btn-city">
                        <img src={`${process.env.PUBLIC_URL}/images/icon-back.png`} alt="Voltar" />
                    </Link>
                    <h1>{city.name} <span>{categoryName}</span></h1>
                </div>
                { (city.tips && city.tips.constructor === Array && city.tips.length > 0) ? <CategorySection tips={city.tips} /> : null}
                { (city.images && city.images.constructor === Array && city.images.length > 0) ? <CategoryGallery images={city.images} /> : null}
            </div>
            <Terms />
            {loading ? <Loading /> : null}
        </>
    )
}

export default Category;