import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './style.css';
import CityCard from '../CityCard';
import Loading from '../Loading';
import Terms from '../Terms';

const City = () => {

    const url = 'https://visa.blumerland.com.br/api/city/';
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [city, setCity] = useState({});
    const dataStored = useSelector(state => state.data);
    const dispatch = useDispatch();

    const cityInfo = {
        en: 'Choose a <br />category to check <br />out the tips!',
        es: '¡Escoja una <br />categoría para <br />ver los consejos!',
        pt: 'Escolha uma <br />categoria para <br />conferir as dicas!'
    }

    const [backgroundLoaded, setBackgroundLoaded] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        if (dataStored[params.lang].length > 0) {
            const newData = dataStored[params.lang].filter(element => element.url === params.city);
            if (newData.length > 0) {
                setCity(newData[0]);
                setDataLoaded(true);
            } else {
                getData();
            }
        } else {
            getData();
        }
    }, [dataStored, params]);

    const getData = () => {
        fetch(`${url}${params.city}/${params.lang}`)
            .then(response => response.json())
            .then(data => {
                const newDataStored = dataStored;
                newDataStored[params.lang].push(data.city);
                setCity(data.city);
                setDataLoaded(true);
                dispatch({ type: 'CHANGE_DATA', data: newDataStored });
            });
    }

    const handleBackgroundLoaded = (type) => {
        setBackgroundLoaded(type);
    }

    useEffect(() => {
        if(backgroundLoaded && dataLoaded){
            setLoading(false);
        }
    }, [backgroundLoaded, dataLoaded]);

    return (
        <>
            <div className={loading ? "city" : "city active"}>
                <img src={city.background} onLoad={() => handleBackgroundLoaded(true)} className="background" />
                <Link to={`/${params.lang}/globe`} className="btn-globe">
                    <img src={`${process.env.PUBLIC_URL}/images/icon-globe.png`} alt="Voltar" />
                </Link>
                <p className="city-info" dangerouslySetInnerHTML={{ __html: cityInfo[params.lang] }}></p>
                <CityCard city={city} loading={loading} />
            </div>
            <Terms />
            {loading ? <Loading /> : null}
        </>
    )
}

export default City;