import { useDispatch } from 'react-redux';
import { Routes, Route } from "react-router-dom";

import './style.css';

import GlobeWrapper from '../GlobeWrapper';
import Benefits from '../Benefits';
import City from '../City';
import Category from '../Category';

const Content = (props) => {

    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch({ type: 'CHANGE_MENU', menu: false });
    }

    return (
        <div className="content" onClick={() => handleClick()}>
            <Routes>
                <Route index path="/" element={<GlobeWrapper />} />
                <Route index path="/:lang" element={<GlobeWrapper />} />
                <Route index path="/:lang/globe" element={<GlobeWrapper />} />
                <Route index path="/:lang/globe/:city" element={<City />} />
                <Route index path="/:lang/globe/:city/:category" element={<Category />} />
                <Route path="/:lang/benefits" element={<Benefits />} />
            </Routes>
        </div>
    )
}

export default Content;