import './style.css';
import { useParams } from 'react-router-dom';

const BenefitsCategoryOne = (props) => {

    const params = useParams();

    return (
        <div className="benefits-category-one">
            <img src={`${process.env.PUBLIC_URL}/images/benefits/${params.lang}/concierge/0.png`} className="only-desktop" />
            <img src={`${process.env.PUBLIC_URL}/images/benefits/${params.lang}/concierge/mobile-0.png`} className="only-mobile" />
            <img src={`${process.env.PUBLIC_URL}/images/benefits/${params.lang}/concierge/mobile-1.png`} className="only-mobile" />
        </div>
    )
}

export default BenefitsCategoryOne;