import { createStore } from 'redux'

const INITIAL_STATE = {
	initial: true,
	language: 'pt',
	content: {
		culture: [
			{ language: 'en', name: 'Culture' },
			{ language: 'es', name: 'Cultura' },
			{ language: 'pt', name: 'Cultura' },
		],
		experience: [
			{ language: 'en', name: 'Experience' },
			{ language: 'es', name: 'Experiencia' },
			{ language: 'pt', name: 'Experiência' },
		],
		gastronomy: [
			{ language: 'en', name: 'Gastronomy' },
			{ language: 'es', name: 'Gastronomía' },
			{ language: 'pt', name: 'Gastronomia' },
		]
	},
	cities: [],
	data: {
		en: [],
		es: [],
		pt: [],
	},
	menu: false
}

function appData(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'CHANGE_INITIAL':
			return { ...state, initial: action.initial };
		case 'CHANGE_CITIES':
			return { ...state, cities: action.cities };
		case 'CHANGE_DATA':
			return { ...state, data: action.data };
		case 'CHANGE_MENU':
			return { ...state, menu: action.menu };
		default:
			return state;
	}
}

const store = createStore(appData);

export default store;